import React from "react";
import GoogleLoginButton from "./GoogleLoginButton";

const LoginPanel = () => {
    return (
        <div className="max-w-lg my-6">
            <div className="flex flex-col w-full items-center bg-gray-100 p-6 rounded-lg">
                <p className="text-gray-600 text-center">
                    Log in to your account to continue.
                </p>
                <div className="mt-3">
                    <GoogleLoginButton />
                </div>
            </div>
        </div>
    )
}

export default LoginPanel;