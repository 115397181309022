import React, { useEffect, useState, useContext } from "react";
import ChatMessage from "../QuestionContainer";
import * as Context from "../../Context";

const EmailField = ({ value="", required = true, isValid, onValueChange,  formData}) => {
    const [error, setError] = useState("");
    const [email, setEmail] = useState(value);
    const { user } = useContext(Context.UserContext);

    const validate = (inputValue) => {
        inputValue = inputValue?.trim()
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!inputValue && required) {
            return "Email is required!";
        }
        else if (inputValue && !emailRegex.test(inputValue)) {
            return "Enter valid email address!";
        }
        return "";
    }

    const validateAndReturn = (value) => {
        setEmail(value);
        const errorMsg = validate(value);
        if (errorMsg) {
            setError(errorMsg);
            isValid(false);
            return;
        }
        setError(null);
        isValid(true);
        onValueChange(value);
    }

    const onChange = (e, value) => {
        value = e.target.value;
        validateAndReturn(value);
    }

    useEffect(() => {
        if (!!!value && user) {
            value = user.email
        }
        validateAndReturn(value)
    }, []);

    const classNames = error ? "border-red-500" : "border-gray-300";

    return (
        <div className="w-[280px] md:w-[350px] flex flex-col items-center justify-center">
            <ChatMessage message="Enter your email Id?" isRequired={required}/>
            <input
                type="email"
                id="email_id"
                value={email}
                className={`${classNames} border bg-gray-50 text-gray-900 text-sm rounded-lg block w-full p-2.5 mt-3`}
                placeholder="e.g hello@example.com"
                onChange={onChange}
                autoFocus={false}
            />
            {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
        </div>
    )
}
export default EmailField;