import React from "react";
import { Link } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import * as routes from "../routes";

const BackToHome = ({ textOnly = false }) => {
    return (
        <Link to={routes.Home}>
            {
                textOnly ?
                    (
                        <div className="flex items-center align-middle cursor-pointer mt-5">
                            <BsArrowLeftShort size={22} />
                            <div>Back to home</div>
                        </div>
                    )
                    : (
                        <button className="p-4 px-8 bg-black text-white rounded-md flex align-middle items-center gap-2">
                            <BsArrowLeftShort size={22} />
                            <div>Back to home</div>
                        </button>
                    )
            }
        </Link >
    )
}
export default BackToHome;