import React, { useState, useEffect } from 'react';
import { FiWifiOff } from "react-icons/fi";

const InternetStatus = ({ children }) => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    return (

        <div>
            {
                isOnline
                    ? children
                    : (
                        <div className='flex flex-col justify-center text-center items-center text-gray-700 gap-2 h-[80vh]'>
                            <FiWifiOff size={70} className='text-gray-500' />
                            You are offline! Please check your internet connection.
                        </div>
                    )
            }
        </div>
    );
};

export default InternetStatus;
