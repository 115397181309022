import React, { useEffect, useState, useContext } from "react";
import ChatMessage from "../QuestionContainer";
import * as Context from "../../Context";

const NameField = ({ value = "", required = true, isValid, onValueChange, formData }) => {
    const [error, setError] = useState(null);
    const [name, setName] = useState(value);
    const { user } = useContext(Context.UserContext);

    const validate = (inputValue) => {
        inputValue = inputValue?.trim()
        if (!inputValue && required) {
            return "Full name is required!";
        }
        else if (inputValue && inputValue.length < 5) {
            return "Full name must be more than 4 letters!";
        }
        else if (inputValue && inputValue.split(" ").length < 2) {
            return "Enter both first name & last name!";
        }
        return ""
    }

    const validateAndReturn = (value) => {
        setName(value);
        const errorMsg = validate(value);
        if (errorMsg) {
            setError(errorMsg);
            isValid(false);
            return;
        }
        setError(null);
        isValid(true);
        onValueChange(value);
    }

    const onChange = (e, value) => {
        value = e.target.value;
        validateAndReturn(value);
    }

    useEffect(() => {
        if (!!!value && user) {
            value = user.displayName
        }
        validateAndReturn(value)
    }, []);

    const classNames = error ? "border-red-500" : "border-gray-300";

    return (
        <div className="w-[280px] md:w-[350px] flex flex-col items-center justify-center">
            <ChatMessage message="Enter your name?" isRequired={required} />
            <input
                type="text"
                id="first_name"
                value={name}
                className={`${classNames} border bg-gray-50 text-gray-900 text-sm rounded-lg block w-full p-2.5 mt-3`}
                placeholder="e.g John Doe"
                onChange={onChange}
                autoFocus={false}
            />
            {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
        </div>
    )
}
export default NameField;