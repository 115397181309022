import React from "react";

const UserProfileBadge = ({ name, email, role, photoURL, photSize=40}) => {
    return (
        <div className={`flex gap-2 items-center ${photoURL ? 'justify-center' : null}`}>
            {
                photoURL &&
                <div className="flex items-center">

                    <img
                        className="rounded-full border-2 border-gray-200"
                        src={photoURL}
                        alt="Photo"
                        width={photSize}
                    />
                </div>
            }
            <div className="flex flex-col">
                {name && <p className="text-base font-semibold leading-none text-gray-900">{name}</p>}
                {email && <p className="text-sm font-normal leading-none">{email}</p>}
                {role && <p className="py-1 text-xs font-normal leading-none">{role}</p>}
            </div>
        </div>
    )
}
export default UserProfileBadge;