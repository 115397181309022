import * as Constants from "../constants";
import { collection, getDocs, where, serverTimestamp, query, addDoc } from "firebase/firestore";
import { db } from "../Libs/firebase";

const collectionRef = collection(db, Constants.FC_USERS);

export const getUserByEmail = async (email) => {
  try {
    const q = query(collectionRef, where('email', '==', email));
    const querySnapshot = await getDocs(q);

    let user = null;
    querySnapshot.forEach((doc) => {
      if (doc.data().email === email) {
        user = { ...doc.data(), id: doc.id };
      }
    });

    return { data: user, error: null };
  } catch (error) {
    return { data: null, error };
  }
}

export const addUser = async (data) => {
  try {
    const user = await getUserByEmail(data.email);

    if (!user.data) {
      const newData = { ...data, created_at: serverTimestamp() };
      const _data = await addDoc(collectionRef, newData);
      user.data = { ...newData, id: _data.id };
    }

    return { data: user.data, error: null }
  } catch (error) {
    return { data: null, error }
  }
}

export const getUsers = async () => {
  try {
    const users = [];

    const q = query(
      collectionRef,
      where(
        'role',
        'in',
        [Constants.USER_GROUPS.ADMIN, Constants.USER_GROUPS.EMPLOYEE]
      )
    )
    const querySnapshot = await getDocs(q);
    
    querySnapshot.forEach((doc) => {
      users.push({ id: doc.id, ...doc.data() });
    });

    return { data: users, error: null };
  } catch (error) {
    return { data: [], error: error };
  }
};
