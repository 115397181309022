import React, { useEffect, useState, useRef } from 'react';
import { v4 } from "uuid";
import { ref, uploadBytesResumable } from "firebase/storage";
import { storage } from '../Libs/firebase';
import { MdUploadFile } from "react-icons/md";

const FileUpload = ({ onUpload, title, prefix = '',  uploadPath = null }) => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState();
    const [progress, setProgress] = useState(0);
    const fileInputRef = useRef(null);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setError(null);
            uploadFileToFirebase();
        } else {
            setFile(null);
            setError('Please select a file.');
        }
    };

    const uploadFileToFirebase = () => {
        if (!file) return;

        const fileId = v4();
        const file_parts = file.name.split('.');
        const file_ext = file_parts.pop();
        const file_name = file_parts.join('.');

        let _fileName = `${prefix ? prefix : file_name}.${fileId}.${file_ext}`;
        _fileName = uploadPath ? `${uploadPath}/${_fileName}` : _fileName;
        
        const storageRef = ref(storage, _fileName);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(Math.floor(progress))
            },
            (error) => {
                setError("File upload failed!")
            },
            async () => {
                onUpload(uploadTask.snapshot.metadata.fullPath);
                setFile(null);
                fileInputRef.current.value = '';
            }
        );
    };

    useEffect(() => {
        uploadFileToFirebase();
    }, [file])

    return (
        <div>
            {
                progress > 0 && progress < 100
                    ? <>
                        <label className='flex gap-2 w-full text-xs bg-slate-400 text-white rounded-md'>
                            <label className={`text-xs text-right p-2 bg-slate-800 text-white rounded-md`} style={{ width: `${progress}%` }}>
                                {progress}%
                            </label>
                        </label>
                    </>
                    : <>
                        <input
                            id="document_file"
                            type="file"
                            onChange={handleFileChange}
                            disabled={progress > 0 && progress < 100}
                            ref={fileInputRef}
                            multiple={false}
                            className="hidden"
                        />
                        <label for="document_file" className='flex items-center gap-2 cursor-pointer bg-slate-800 p-2 w-auto text-white rounded-md'>
                            <MdUploadFile size={18} />
                            <span className='text-xs'>{title}</span>
                        </label>
                        {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
                    </>
            }
        </div>
    );
};

export default FileUpload;
