const RENEW_FORM_CONFIG = [
    {
        field: "service_policy_option",
        isRequired: true,
        dependsOn: [],
        isLast: false,
        order: 1,
    },
    {
        field: "previous_policies",
        dependsOn: [],
        isRequired: false,
        isLast: false,
        order: 7,
    },
    {
        field: "text_input",
        dependsOn: [],
        isRequired: false,
        isLast: false,
        order: 14,
    },

    /**
     * Todo: Bug - gotoNextStep fails if last step is depends on other fields 
     * but value of others fields is not defined
     */
    // {
    //     field: "email",
    //     dependsOn: [],
    //     isRequired: false,
    //     isLast: true,
    //     order: 14,
    // }
    {
        field: "mobile_no",
        dependsOn: [],
        isRequired: true,
        isLast: true,
        order: 3,
    },
]

export default RENEW_FORM_CONFIG;