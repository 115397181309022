import React, { useEffect, useContext } from 'react';
import { signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from '../Libs/firebase';
import { FcGoogle } from "react-icons/fc";
import { TbLogout } from "react-icons/tb";
import * as Context from "../Context";
import * as Utils from "../Utils";
import * as UserService from "../Services/users";
import * as Constants from "../constants";

const GoogleLoginButton = ({ hidePhoto = false }) => {
  const { user, setUser } = useContext(Context.UserContext);

  const handleGoogleLogin = async () => {
    signInWithPopup(auth, googleProvider).then(async (result) => {
      let _user = result.user;
      let userData = {
        "uid": _user.uid,
        "email": _user.email,
        "emailVerified": _user.emailVerified,
        "displayName": _user.displayName,
        "isAnonymous": _user.isAnonymous,
        "photoURL": _user.photoURL,
        "lastLoginAt": _user.metadata.lastLoginAt,
        "createdAt": _user.metadata.createdAt,
      }

      // Create user entry in collection if new user
      const db_user = await UserService.addUser({
        'name': _user.displayName,
        'email': _user.email,
        'role': Constants.USER_GROUPS.USER
      });

      // Patch user role in session
      if (db_user.data) {
        userData['role'] = db_user.data.role;
      }
      setUser(userData);
      Utils.setUser(userData);
    }).catch(err => {
      console.log("Google Error:", err);
    });
  };

  const logOut = () => {
    Utils.removeUser();
    setUser(null);
    auth.signOut();
  }

  useEffect(() => {
    const user = Utils.getUser();
    setUser(user);
  }, [])

  return (
    <div>
      {user ? (
        <div>
          {
            hidePhoto
              ? <button
                onClick={logOut}
                className="p-2.5 px-3 bg-red-500 text-white rounded-md flex align-middle items-center gap-1"
              >
                <TbLogout size={20} />
                <p>Logout</p>
              </button>
              : <button
                onClick={logOut}
                className="p-2.5 px-4 bg-black text-white rounded-md flex align-middle items-center gap-2"
              >
                <div className={`bg-white rounded-full`}>
                  <img src={user?.photoURL} width={25} />
                </div>
                <p>Logout</p>
              </button>
          }
        </div>
      ) : (
        <button
          onClick={handleGoogleLogin}
          className="p-2.5 px-6 bg-black text-white rounded-md flex align-middle items-center"
        >
          <FcGoogle className="mr-3" /> Login
        </button>
      )}
    </div>
  );
};

export default GoogleLoginButton;
