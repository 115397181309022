import React, { useState } from "react";
import { useEffect } from "react";
import * as Utils from "../Utils/index";
import * as UserService from "../Services/users";

export const UserContext = React.createContext();

const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState("");
    const [users, setUsers] = useState([]);

    const values = {
        user,
        setUser,
        users,
        setUsers,
    }

    useEffect(() => {
        const user = Utils.getUser();
        setUser(user);
        UserService.getUsers().then((result) => {
            setUsers(result.data);
        });
    }, [])

    return (
        <UserContext.Provider value={values}>
            {children}
        </UserContext.Provider>
    )
}
export default UserContextProvider;